const links = [
  {
    match: ['M', 'a', 'r', 'i', 'a', '&nbsp;', 'A', 'd', 'e', 'l', 'a', 'i', 'd', 'e'],
    link: 'https://mariaadelaide.com'
  },
  {
    match: ['E', 'm', 'a', 'i', 'l'],
    link: 'mailto:afilipaspereira@gmail.com'
  },
  {
    match: ['G', 'i', 't', 'H', 'u', 'b'],
    link: 'https://github.com/afilipapereira'
  },
];

export class SpanTransformer {
  constructor(el) {
    this.el = el;

    this.text = el.getAttribute('data-about-text');
    this.newText = '';
    this.spanEls = undefined;

    this.isAnimating = false;
    this.shakeForce = 0;

    this._transformToSpans();
    this.spanEls = el.querySelectorAll('span');

    this._checkForLinks();
    this._addFadeInAnimation();
  }

  _transformToSpans() {
    this.text.split('').forEach(char => {
      if(char === '\n') {
        this.newText += '<br>';
      } else {
        const charSymbol =  char === ' ' ? '\u00A0' : char;
        this.newText += '<span>'+charSymbol+'</span>';
      }
    });

    this.el.innerHTML = this.newText;
  }

  _checkForLinks() {
    let matchIndex = 0;
    let spanGroup = [];

    links.forEach(link => {
      this.spanEls.forEach((span) => {
        if (span.innerHTML === link.match[matchIndex]) {
          spanGroup.push(span);
          matchIndex++;

          if (matchIndex === link.match.length) {
            const linkEl = document.createElement('a');
            linkEl.href = link.link;
            linkEl.target = '_blank';

            const firstSpan = spanGroup[0];
            firstSpan.parentNode.insertBefore(linkEl, firstSpan);

            spanGroup.forEach((s) => linkEl.appendChild(s));

            matchIndex = 0;
            spanGroup = [];
          }
        } else {
          matchIndex = 0;
          spanGroup = [];
        }
      });
    });
  }

  _addFadeInAnimation() {
    this.spanEls.forEach((el,i) => {
      const s = i * 0.01 + 0.5;
      el.style.transition = `transform 0.5s ease-in, opacity 0.5s ease-in ${s}s`;
      setTimeout(() => el.style.opacity = 1, 100);
    });
  }

  addHoverInteration() {
    this.spanEls.forEach((el,i) => {
      let backToPlaceTimeout;
      setTimeout(() => {
        this.el.classList.add('about__content--ready');
        el.addEventListener('mouseenter', () => {
          const randomX = Math.random() * 30 - 10;
          const randomY = Math.random() * 30 - 10;
          const rotate = Math.random() * 2.5 - 2.5;
          
          el.style.transitionDuration = '0.5s';
          el.style.transform = `translate(${randomX}px, ${randomY}px) rotate(${rotate}deg)`;

          backToPlaceTimeout = setTimeout(() => el.style.transform = `translate(0,0) rotate(0deg)`, 1000);
        });
        el.addEventListener('mouseleave', () => {
          clearTimeout(backToPlaceTimeout);
          el.style.transitionDuration = '0.75s';
          setTimeout(() => el.style.transform = `translate(0,0) rotate(0deg)`, 450);
        });
      }, 3000);
    });
  }

  addShakeInteraction () {
    let lastShakeTime = 0;
    const SHAKE_THRESHOLD = 11.5; // Adjust based on sensitivity
    let spanBackToPlaceTimeout;

    window.addEventListener('devicemotion', (event) => {
      const acceleration = event.accelerationIncludingGravity;
      if (!acceleration) return;

      // Calculate total acceleration force
      let gravity = 9.8; // Earth's gravity (m/s²)
      this.shakeForce = Math.sqrt(
        Math.pow(acceleration.x, 2) +
        Math.pow(acceleration.y, 2) +
        Math.pow(acceleration.z, 2) // Subtract gravity from z-axis acceleration
      );
      if (this.shakeForce > SHAKE_THRESHOLD) {
        let now = Date.now();
        
        // Prevent rapid multiple triggers
        if (now - lastShakeTime > 750) {
          clearTimeout(spanBackToPlaceTimeout);
          
          this._moveSpansAllOverThePlace();
          spanBackToPlaceTimeout = setTimeout(() => this._moveSpansBackToPlace(), 1500);
          
          lastShakeTime = now;
        }
      }
    });

  }

  _moveSpansBackToPlace() {
    this.spanEls.forEach((el,i) => {
      el.style.transitionDuration = '0.75s';
      el.style.transform = `translate(0px, 0px) rotate(0deg)`;
    });
  }

  _moveSpansAllOverThePlace() {
    if(this.isAnimating) return;

    this.isAnimating = true;
    this.changePosRelatedToShakeForce = this._mapRangeExponential(this.shakeForce, 0, 18, 0, 1);

    this.spanEls.forEach((el,i) => {
      let minChangeX = el.getBoundingClientRect().left * -1;
      let maxChangeX = window.innerWidth - el.getBoundingClientRect().left - el.getBoundingClientRect().width /3;
      if(i < 30) {
        minChangeX = el.getBoundingClientRect().left * -1 /2;
        maxChangeX = (window.innerWidth - el.getBoundingClientRect().left - el.getBoundingClientRect().width);
      }
      if(i >= 30 && i < 100) {
        minChangeX = el.getBoundingClientRect().left * -1 /3;
        maxChangeX = (window.innerWidth - el.getBoundingClientRect().left - el.getBoundingClientRect().width) /2;
      }

      const minChangeY = el.getBoundingClientRect().top * -1;
      let maxChangeY = window.innerHeight/3 - (window.scrollY + el.getBoundingClientRect().top) - el.getBoundingClientRect().height;
      if(i < 30)
        maxChangeY = window.innerHeight - (window.scrollY + el.getBoundingClientRect().top) - el.getBoundingClientRect().height;
      if(i >= 30 && i < 100)
        maxChangeY = window.innerHeight/2 - (window.scrollY + el.getBoundingClientRect().top) - el.getBoundingClientRect().height;

      const translateX = (Math.random() * (maxChangeX - minChangeX) + minChangeX) * this.changePosRelatedToShakeForce;
      const translateY = (Math.random() * (maxChangeY - minChangeY) + minChangeY) * this.changePosRelatedToShakeForce;
      const rotate = Math.random() * 10 - 10;

      el.style.transitionDuration = '0.75s';
      el.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`;
    });
    setTimeout(() => this.isAnimating = false, 750);
  }

  _mapRange(value, originalMin, originalMax, targetMin, targetMax) {
    if (value < originalMin || value > originalMax) {
      console.warn("Value is out of the expected range:", value);
      return value;
    }
    const scaledValue = (value - originalMin) / (originalMax - originalMin);
    const mappedValue = targetMin + scaledValue * (targetMax - targetMin);

    return mappedValue;
  }
  _mapRangeExponential(value, originalMin, originalMax, targetMin, targetMax, exponent = 5, midPoint = 1, scalingFactor = 0.1) {
    if (value > originalMax)
      return 1;
    if (value < originalMin)
      return 0;

    // Normalize to 0-1 range
    let scaledValue = (value - originalMin) / (originalMax - originalMin);

    // Apply exponential function
    let expValue = Math.pow(scaledValue, exponent); // Exponential scaling

    // Remap to target range
    let mappedValue = targetMin + expValue * (targetMax - targetMin);

    if(value <= 14)
      mappedValue = mappedValue * scalingFactor;

    return mappedValue;
  }
}