// import { gsap } from "gsap";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import debounce from 'lodash/debounce';

import { SpanTransformer } from "./span-transformer.module";

// gsap.registerPlugin(ScrollToPlugin);

const docReady = (fn) => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}
const isMobileView = () => window.innerWidth <= 1023;

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
docReady(() => {
  //Desktop
  const aboutParagraphDesktop = document.querySelector('[data-about-paragraph-desktop]');
  if (aboutParagraphDesktop) {
    const spansDesktop = new SpanTransformer(aboutParagraphDesktop);
    spansDesktop.addHoverInteration();
  }

  //Tablet
  const aboutParagraphTablet = document.querySelector('[data-about-paragraph-tablet]');
  if (aboutParagraphTablet) {
    const spansTablet = new SpanTransformer(aboutParagraphTablet);
    spansTablet.addShakeInteraction();
  }

  //Mobile
  const aboutParagraphMobile = document.querySelector('[data-about-paragraph-mobile]');
  if (aboutParagraphMobile) {
    const spansMobile = new SpanTransformer(aboutParagraphMobile);
    spansMobile.addShakeInteraction();
  }


  document.addEventListener('mousemove', event => {
    const yPos = event.pageY;
    const heightRow = document.querySelector('.project--heading').getBoundingClientRect().height;
    const hoverLineYPos = (yPos - window.scrollY - heightRow/2);

    if(hoverLineYPos > document.querySelector('.projects').getBoundingClientRect().top
    && (yPos - heightRow/2 - window.scrollY) > heightRow
    && (hoverLineYPos < window.innerHeight - heightRow)) {
      document.querySelector('.projects__hovered-proj').style.top = hoverLineYPos +'px';
      document.querySelector('.project--heading').style.top = hoverLineYPos +'px';
    }
  });

  const debouncedMouseEnter = debounce((el) => {
    const videoEl = el.querySelector('.project__column-img video');

    if(videoEl) {
      const videoId = videoEl.getAttribute('data-video-id');
      document.querySelector('[data-video-bg="'+videoId+'"]').classList.add('project-bg__video--active');
    }
  }, 200);

  document.querySelectorAll('.projects__group .project a').forEach(el => el.addEventListener('click', e => e.stopPropagation()));

  const k = Math.log(0.25 / 0.05) / document.querySelectorAll('.projects__group .project:not(.project--year)').length; 
  let delay = 1;
  

  const observerCallback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting)
        entry.target.classList.add('in-viewport');
      else
        entry.target.classList.remove('in-viewport');
    });
  };
  const observer = new IntersectionObserver(observerCallback, {
    rootMargin: '10%',
    threshold: 0.1 
  });

  document.querySelectorAll('.projects__group .project:not(.project--year)').forEach((el, i) => {
    delay += (0.15 * Math.exp(-k * i));
    el.style.animationDelay = `${delay}s`;

    el.addEventListener('mouseenter', () => {
      debouncedMouseEnter.cancel(); 
      debouncedMouseEnter(el);
    });

    el.addEventListener('mouseleave', () => {
      debouncedMouseEnter.cancel(); 

      const videoEl = el.querySelector('.project__column-img video');
      if(videoEl) {
        const videoId = videoEl.getAttribute('data-video-id');
        document.querySelector('[data-video-bg="'+videoId+'"]').classList.remove('project-bg__video--active');
      }
    });

    el.addEventListener('click', () => {
      const videoEl = el.querySelector('.project__column-img video');
      const isActive = el.classList.contains('project--active');

      if(videoEl) {
        const videoHqSrc = videoEl.getAttribute('data-video-src');

        if(videoEl.getAttribute('data-is-video-hq') !== 'true') {
          videoEl.insertAdjacentHTML('afterbegin', `<source src="https://pub-c336f26f70c841309ac8d8ab67e766f8.r2.dev/${videoHqSrc}" type="video/mp4">`);
          
          videoEl.addEventListener('canplay', () => {
            el.classList.add('project--video-loaded');
            videoEl.play();
          });
        }
      }

      if(isActive) {
        el.classList.remove('project--active');
        document.querySelector('.projects__hovered-proj').classList.remove('projects__hovered-proj--active');
        videoEl.pause();
      } else {
        document.querySelectorAll('.project').forEach(el => el.classList.remove('project--active'));
        el.classList.add('project--active');
        document.querySelector('.projects__hovered-proj').classList.add('projects__hovered-proj--active');
        videoEl.play();
        
        setTimeout(() => {
          const projectTop = el.getBoundingClientRect().top;
          const remInPx = parseFloat(getComputedStyle(el).fontSize); 
          const headingTop = isMobileView() ? (remInPx * 2 + 15) : (remInPx * 1.3 + 15);
        
          // if (projectTop < 0 || projectTop + el.clientHeight/4 > window.innerHeight)
          //   gsap.to(window, { duration: 0.75, scrollTo: { y: (projectTop - headingTop + window.scrollY)} });
        }, 1000);
      }
    });

    observer.observe(el);
  });
});
